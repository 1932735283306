import React from 'react';
import {Form, Input, Button, message} from 'antd';
import styled from 'styled-components';
import imgLogo from '../../images/logo.png';
import * as AppContext from '../../AppContext';
import * as AppActions from '../../AppActions';
import {navigate} from 'gatsby';
import URL_PATH from '../../UrlPath';

function LoginPage(props) {
  const app = React.useContext(AppContext.Context);
  const [isLogining, setIsLogining] = React.useState(false);
  const [form] = Form.useForm();

  const _login = React.useCallback(
    async (values) => {
      if (!values.username || !values.password) {
        return;
      }

      AppActions.setLoading(true);
      let profile = null;
      try {
        profile = await app.actions.staffLogin({
          username: values.username,
          password: values.password,
        });

        navigate(URL_PATH.dashboardHome);
      } catch (err) {
        console.log('err', err);
        //
        message.error('登入失敗！請確認帳號密碼無誤');
      }
      AppActions.setLoading(false);
    },
    [app.actions],
  );

  return (
    <Wrapper>
      <Container>
        <img className="logo" src={imgLogo} />
        <p>法朋後台使用者您好，請先登入</p>
        <div className="content">
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{username: 'staff', password: 'staff123456'}}
            form={form}
            name="basic"
            onFinish={_login}
            style={{width: '100%'}}>
            <Form.Item
              name="username"
              rules={[{required: true, message: '請輸入使用者帳號'}]}
              style={{marginBottom: 10}}>
              <Input placeholder="帳號" size="large" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{required: true, message: '請輸入密碼'}]}
              style={{marginBottom: 16}}>
              <Input.Password placeholder="密碼" size="large" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLogining}
                style={{width: '100%', marginBottom: 10}}
                onClick={_login}>
                立即登入
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const Container = styled.div`
  text-align: center;
  padding-top: 16px;
  width: 600px;
  max-width: 100%;
  & > .logo {
    margin: 0 auto;
    margin-bottom: 16px;
    height: 50px;
  }

  & > p {
    margin-bottom: 16px;
    font-size: 16px;
  }

  & > .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px;

    & > .separator {
      width: 100%;
      display: flex;
      align-items: center;

      &:before {
        content: '';
        display: block;
        flex: 1;
        border-top: 1px solid grey;
        margin-right: 10px;
      }

      &:after {
        content: '';
        display: block;
        flex: 1;
        border-top: 1px solid grey;
        margin-left: 10px;
      }
    }
  }

  /* overwrite antd form item style */
  & form {
    & .ant-form-item-explain.ant-form-item-explain-error {
      text-align: right;
    }

    & .error-hint {
      color: #ff4d4f;
      margin-bottom: 5px;
    }
  }
`;

export default LoginPage;
